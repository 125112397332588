import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import MainLayout from '@layouts/MainLayout'

export default function EssayList({ data }) {
  const { edges: posts } = data.allMdx
  return (
    <MainLayout>
      <Helmet>
        <title>Ramblings</title>
        <description>Random ramblings and writings.</description>
      </Helmet>
      <div className='flex flex-col'>
        <Typography.Title
          level={1}
          className='text-primary-color font-headers font-bold'
        >
          Ramblings
        </Typography.Title>
        <ul>
          {posts.map(({ node: post }) => (
            <li key={post.id}>
              <Link to={post.fields.slug}>
                <Typography.Title
                  level={4}
                  className='text-primary-color font-headers font-bold m-0'
                >
                  {post.frontmatter.title}
                </Typography.Title>
              </Link>
              <small>
                {post.frontmatter.date} &mdash; {post.fields.readingTime.text}
              </small>
              <p className='mb-4'>{post.frontmatter.subtitle}</p>
            </li>
          ))}
        </ul>
      </div>
    </MainLayout>
  )
}

EssayList.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query blogIndex {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            flavor
            time
            title
            subtitle
          }
          fields {
            slug
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`
